import { fetchWithCache, fetchWithPromise } from './fetch-with-cache';
import { makeRequest } from './make-request';
import { DateTime } from 'luxon';

export async function queryNeedsAppts(settingId, leadStatusNames, start, end, page, limit, market) {
  const key = `query-needs-appts-${settingId}-${start}-${end}-${leadStatusNames}`;
  const params = {
    settingId,
    leadStatusNames,
    start,
    end,
    page,
    limit
  };
  if (market && market !== 'all') {
    params.market = market;
  }
  const { data, errorMessage } = await fetchWithPromise(key, `crm-query/needs-appointments`, params);
  if (errorMessage) {
    return { errorMessage };
  }
  const { records, totalCount, beforeExclude } = data;
  return { records, totalCount, beforeExclude };
}

export async function queryNeedsApptsCount(
  settingId,
  start,
  end,
  market,
  queue,
  excludeDays,
  excludeSources,
  leadStatusNames,
  prospectType,
  forType,
  queueAll
) {
  const key = `query-needs-appts-count-${settingId}-${start}-${end}-${market}-${queue}-${excludeDays}-${excludeSources}-${leadStatusNames}-${prospectType}-${forType}-${queueAll}`;
  const params = {
    settingId,
    start,
    end,
    excludeDays,
    excludeSources,
    leadStatusNames,
    prospectType,
    forType,
    queueAll
  };
  if (market && market !== 'all') {
    params.market = market;
  }
  if (queue && queue !== 'all') {
    params.queue = queue;
  }
  const { data, errorMessage } = await fetchWithPromise(key, `crm-query/needs-appts-count`, params);
  if (errorMessage) {
    return { errorMessage };
  }
  const { totalCount, beforeExclude } = data;
  return { totalCount, beforeExclude };
}

export async function queryLeadsManualCount(settingId, leadIds, excludeDays, forType) {
  const body = {
    settingId,
    leadIds,
    excludeDays,
    forType
  };
  const { data, errorMessage } = await makeRequest('post', 'crm-query/manual-count', body);
  if (errorMessage) {
    return { errorMessage };
  }
  const { totalCount, beforeExclude } = data;
  return { totalCount, beforeExclude };
}

export async function loadMapFile() {
  const { data, errorMessage } = await fetchWithPromise(`load-map`, `google-maps/load`);
  return data ? { data } : { errorMessage };
}

export async function querySmsPricing(settingId) {
  const key = `query-sms-pricing-${settingId}`;
  const params = { settingId };
  const { data, errorMessage } = await fetchWithPromise(key, `crm-query/sms-pricing`, params);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function querySmsDetails(settingId) {
  const key = `query-sms-details-${settingId}`;
  const params = { settingId };
  const { data, errorMessage } = await fetchWithPromise(key, `outbound-sms/details`, params);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function queryEmailDetails(settingId) {
  const key = `query-email-details-${settingId}`;
  const params = { settingId };
  const { data, errorMessage } = await fetchWithPromise(key, `outbound-email/details`, params);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function queryPendingOutbound(settingId, page, limit) {
  const key = `query-pending-outbound-${settingId}-${page}-${limit}`;
  const params = { settingId, page, limit };
  const { data, errorMessage } = await fetchWithPromise(key, 'crm-query/pending-outbound', params);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function queryPendingOutboundEmails(settingId, page, limit) {
  const key = `query-pending-outbound-emails-${settingId}-${page}-${limit}`;
  const params = { settingId, page, limit };
  const { data, errorMessage } = await fetchWithPromise(key, 'crm-query/pending-outbound-emails', params);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function getLeadPerfectionActive(settingId) {
  const key = `get-lp-active-${settingId}`;
  const params = { settingId };
  const { data, errorMessage } = await fetchWithPromise(key, `webhook/lead-perfection-active`, params);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function getHasTimeBlocks(settingId) {
  const key = `get-has-time-blocks-${settingId}`;
  const { data, errorMessage } = await fetchWithCache(key, `crm-query/has-time-blocks?settingId=${settingId}`);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function getJobNimbusExampleTypes(settingId) {
  const key = `get-job-nimbus-example-types-${settingId}`;
  const { data, errorMessage } = await fetchWithCache(key, `job-nimbus/example-types?settingId=${settingId}`);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function getAcculynxExampleTypes(settingId) {
  const key = `get-acculynx-example-types-${settingId}`;
  const { data, errorMessage } = await fetchWithCache(key, `acculynx/example-types?settingId=${settingId}`);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function queryOfficeSummary(settingId) {
  const key = `query-office-summary-${settingId}`;
  const { data, errorMessage } = await fetchWithCache(key, `crm-query/improveit-office-summary/${settingId}`);
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function queryAvails(crmSettingsId, countryAlpha2, zip, products, prospectId) {
  const key = `query-avails-${crmSettingsId}-${countryAlpha2}-${zip}-${products}-${prospectId}`;
  const { data, errorMessage } = await fetchWithPromise(key, `avails`, {
    crmSettingsId,
    countryAlpha2,
    zip,
    products,
    prospectId
  });
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}

export async function queryDncSearch(crmSettingsId, start, end, sources, markDNC, unMarkDNC) {
  start = DateTime.fromJSDate(new Date(start)).startOf('day').plus({ hours: 12 }).toJSDate();
  end = DateTime.fromJSDate(new Date(end)).startOf('day').plus({ hours: 12 }).toJSDate();
  const key = `query-dnc-search-${crmSettingsId}-${start}-${end}-${sources}-${markDNC}-${unMarkDNC}`;
  const { data, errorMessage } = await fetchWithPromise(key, `crm-query/dnc-search`, {
    crmSettingsId,
    start,
    end,
    sources,
    markDNC,
    unMarkDNC
  });
  if (errorMessage) {
    return { errorMessage };
  }
  return { data };
}
